@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

.rootLogin {
    width: 100%;
    height: 100vh;
    display: flex;
    background: "#FFFFFF";
    position: relative;
    overflow-x: hidden;
  }

  .rootLeft-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rootLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: "Noto Sans JP", sans-serif;
  }

  .rootRight {
    flex: 1;
    background: url('../../assets/Sugarcane.jpg');
    background-size: cover;
    background-position: left;
  }

  .bottomImage{
    position: absolute;
    bottom: 0;
    left:0;
    width:100%;
    height: 33vh;
    background-image: url("../../assets/Mask_Group_2.png");
    background-repeat: no-repeat;
  }

  .leftImage{
    position: absolute;
    top:0;
    left:38%;
    width:100%;
    height: 100vh;
    background-image: url("../../assets/Mask_Group_3.png");
    background-repeat: no-repeat;
  }

  .logIn{
    font-size: 18px;
    text-align: left;
    margin-top:2em;
    margin-bottom: 0.5em;
    font-family: "Noto Sans JP", sans-serif;
  }

  .inputWrapper{
    width:400px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #999999;
    margin: 0.7em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    gap:5px;
  }

  .eyeIcon{
    padding-right: 10px;
  }

  .inputBox{
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: hidden;
    font-size: 15px;
    color:#000;
  }

  .intro h2 {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 12px;
    letter-spacing: 0px;
    opacity: 1;
    margin-top: 2em;
  }

  .intro p {
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 1.5em;
    font-family: "Noto Sans JP", sans-serif;
  }

  .submitButton{
    width:400px;
    height:50px;
    border:none;
    outline: none;
    background: #F37021;
    border-radius: 4px;
    margin: 0.7em 0;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    z-index: 1;
  }

  @media (max-width: 1000px) {
    .rootRight {
      display: none;
    }
  }
  
  @media (max-width: 452px) {
    .input-box,
    button {
      width: 300px;
    }
  }